<template>
    <v-data-table
        :headers="headers"
        :items="applications"
        hide-default-footer
        disable-pagination
    >
        <template v-slot:item.property="{ item }">
            <span class="font-weight-bold">{{ item.meta.property.name }}</span>
            <br />
            {{ item.meta.company.name }}
        </template>
        <template v-slot:item.source="{ item }">
            <v-chip
                small
                label
                style="width: 120px"
                class="text-uppercase justify-center"
                >{{ item.source }}</v-chip
            >
        </template>
        <template v-slot:item.created_at="{ item }">
            <span class="font-weight-bold">{{
                item.created_at.split(" ").shift()
            }}</span
            ><v-chip
                small
                label
                v-if="item.item_new"
                color="error darken-1"
                class="ml-2 text-uppercase text-caption"
                >New</v-chip
            >
            <br />
            <span class="font-weight-light text-caption">{{
                `${item.created_at.split(" ")[1]} ${item.created_at
                    .split(" ")
                    .pop()}`
            }}</span>
        </template>
        <template v-slot:item.applicants="{ item }">
            <application-list-applicant
                v-for="(applicant, index) in item.applicants"
                :key="index"
                :applicant="applicant"
            ></application-list-applicant>
        </template>
        <template v-slot:item.actions="{ item }">
            <application-list-actions
                :applicants="item.applicants"
            ></application-list-actions>
        </template>
        <template v-slot:item.assigned_to="{ item }">
            <application-list-assigned-to
                :key="item.uuid"
                :screeners="screeners"
                :assigned-to="item.assigned_to"
                @update:assignedTo="updateAssignedTo(item.id, $event)"
            ></application-list-assigned-to>
        </template>
    </v-data-table>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "application-list",
    components: {
        ApplicationListApplicant: () => import("./ApplicationListApplicant"),
        ApplicationListActions: () => import("./ApplicationListActions"),
        ApplicationListAssignedTo: () => import("./ApplicationListAssignedTo"),
    },
    props: {
        applications: {
            type: Array,
            required: true,
        },
        screeners: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: "Created",
                    align: "start",
                    sortable: true,
                    value: "created_at",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Property",
                    align: "start",
                    sortable: true,
                    value: "property",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Applicants",
                    align: "start",
                    sortable: false,
                    value: "applicants",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Product",
                    align: "start",
                    sortable: true,
                    value: "meta.product.name",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Source",
                    align: "start",
                    sortable: true,
                    value: "source",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Assigned To",
                    align: "start",
                    sortable: true,
                    value: "assigned_to",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Actions",
                    align: "start",
                    sortable: false,
                    value: "actions",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Uploads", ["updateApplication"]),
        changeAssignedTo(screener) {
            console.log(screener);
        },
        update(event) {
            console.log(event);
        },
        updateAssignedTo(id, user) {
            this.updateApplication({
                id,
                assignedTo: this.$_.pick(user, ["name", "id"]),
            });
        },
    },
};
</script>

<style scoped>
</style>