var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.applications,
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([
      {
        key: "item.property",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(item.meta.property.name))
            ]),
            _c("br"),
            _vm._v(" " + _vm._s(item.meta.company.name) + " ")
          ]
        }
      },
      {
        key: "item.source",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-chip",
              {
                staticClass: "text-uppercase justify-center",
                staticStyle: { width: "120px" },
                attrs: { small: "", label: "" }
              },
              [_vm._v(_vm._s(item.source))]
            )
          ]
        }
      },
      {
        key: "item.created_at",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(item.created_at.split(" ").shift()))
            ]),
            item.item_new
              ? _c(
                  "v-chip",
                  {
                    staticClass: "ml-2 text-uppercase text-caption",
                    attrs: { small: "", label: "", color: "error darken-1" }
                  },
                  [_vm._v("New")]
                )
              : _vm._e(),
            _c("br"),
            _c("span", { staticClass: "font-weight-light text-caption" }, [
              _vm._v(
                _vm._s(
                  item.created_at.split(" ")[1] +
                    " " +
                    item.created_at.split(" ").pop()
                )
              )
            ])
          ]
        }
      },
      {
        key: "item.applicants",
        fn: function(ref) {
          var item = ref.item
          return _vm._l(item.applicants, function(applicant, index) {
            return _c("application-list-applicant", {
              key: index,
              attrs: { applicant: applicant }
            })
          })
        }
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("application-list-actions", {
              attrs: { applicants: item.applicants }
            })
          ]
        }
      },
      {
        key: "item.assigned_to",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("application-list-assigned-to", {
              key: item.uuid,
              attrs: {
                screeners: _vm.screeners,
                "assigned-to": item.assigned_to
              },
              on: {
                "update:assignedTo": function($event) {
                  return _vm.updateAssignedTo(item.id, $event)
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }